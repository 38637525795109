#contact {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.contactTitle {
    font-size: 2rem;
    font-weight: 700;
    color: #bb69e0;
}

.contactDesc {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}
.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40, 40, 40);
}

.btncontact {
    background: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 3.5rem;
    margin: 2rem;
    cursor: pointer;

}

.btncontact:hover {
    background: #bb69e0;
    color: white;
}
