.footer {
    width: 100vw;
    height: 4rem;
    background: rgb(40, 40, 40);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
}

.Button {
    border: none;
    padding: 0.4rem;
    display: flex;
    color: white;
    background: transparent;
    font-size: 1.5rem;
}

.Button:hover {
    color: #bb69e0;
    cursor: pointer;
}