#aboutme {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.AboutMeTitle {
    color: #bb69e0;
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}

.AboutMeDesc {
    font-size: 1rem;
    font-weight: 400;
    max-width: 51rem;
    padding: 0 2rem;
}

.AboutMeBars {
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.AboutMe:h2 {
    color: blueviolet;
}

.AboutMeBar {
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: rgb(50, 50, 50);
}

.AboutMeBar:hover {
    background: rgb(60, 60, 60);
}

.AboutMeImg {
    object-fit: cover;
    height: 4rem;
    width: 4rem;
    margin-right: 2rem;
}

.AboutMeBarTitle {
    font-size: 1.5rem;
    font-weight: 00;
}

.AboutMeBarText{
    font-size: 0.80rem;
    font-weight: 200;
    padding: 0.30rem;
    max-width: 80%;
}
