* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    background: transparent;

}

#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.ProfileImg {
    position: absolute;
    top: 6rem;
    left: 40rem;
    height: 50vh;
    object-fit: cover;
    z-index: -1;
}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.introTitle {
    font-size: 1.75rem;
    font-weight: 100;
}

.introName {
    color: blueviolet;
}

.introPara {
    font-size: medium;
    font-weight: 100;
}

.introLinks{
    display: flex;
}

.btnInfo {
    background-color: white;
    margin: 1rem 0;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 2rem;
}

.btnInfo:hover {
    background-color: #bb69e0;
    color: white;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.Button {
    margin: 2rem 0;
}

@media screen and (max-width: 840px){
    .ProfileImg {
        right: -10vw;
        left: auto;
    }
    .introContent {
        font-size: 7vw;
    }
    .introTitle {
        font-size: 4.5vw;
    }
}

@media screen and (max-width: 480px){
    .ProfileImg {
        right: -50vw;
        left: auto;
    }
    .introContent {
        font-size: 10vw;
    }
    .introTitle {
        font-size: 4.5vw;
    }
}
