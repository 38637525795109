.navbar {
    background: rgb(30, 30, 30);
    height: 5rem;
    width: 100vw;
    margin: 0;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.desktopMenu {
    text-align: center;
}

.desktopMenuList {
    margin: 1rem;
    color: white;
    cursor: pointer;
}

.desktopMenuList:hover {
    color: #bb69e0;
    padding-bottom: 0.5rem;
}

.active {
    color: #bb69e0;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #bb69e0;
}

.desktopMenuBtn {
    background-color: white;
    margin: 1rem 0;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
}