#works {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1reml;
}

.work {
    margin: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.WorksTitle {
  color: #bb69e0;
  margin: 1rem 0;
    font-size: 3rem;
}

.WorksDesc {
  font-weight: 400;
  font-size: 1rem;
  max-width: 51rem;
  padding: 0 2rem;}

.WorksImg {
    object-fit: cover;
    margin: 0.5rem;
    max-width: 28rem;
}

.WorksImgs {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 60rem;
}

.modal {
  background: rgb(50, 50, 50);
  font-size: 12px;
  width: 50%; 
  border-radius: 0.7rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal > .header {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 1.4rem;
  color: #ffffff;
  ;
}
.modal > .content {
  width: 90%;
  margin: auto;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 0.2rem;
  top: 4px;
  font-size: 24px;
  border: none;
  color: #616161;
}

.button {
  background-color: #bb69e0;
  color: white;
  margin: 1rem;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 2rem;
  cursor: pointer;

}